<template>
  <div>
    <div class="row pb-4  df">
      <h2 class="col m-1">{{ $t('desks') }}</h2>
      <span
        v-if="isDashboardAdmin"
        class="mt-2 mb-2 mr-5 btn btn-sm  btn-secondary d-lg-none"
        @click="$bvModal.show('modal-add')"
        >+ {{ $t('add') }}</span
      >
      <div class="col-md-auto m-1 w-md-200">
        <b-form-input
          id="table-filter"
          v-model="filter"
          type="search"
          :placeholder="$t('filterTable')"
        />
      </div>
      <floors-dropdown
        class="col-md-auto m-1"
        v-bind="{
          floors,
          currentFloor
        }"
        :on-floor-selection="handleFloorSelection"
      />
    </div>
    <form class="tabe-responsive" @submit.prevent="processForm">
      <b-table
        small
        :fields="fields"
        :items="filteredDesks"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :no-local-sorting="true"
        :filter="filter"
        :filter-included-fields="[
          'roomName',
          'name',
          'regularUser',
          'attributes'
        ]"
        responsive="sm"
        dark
        class="table-style bootsrap-table table-settings"
      >
        <template #head(roomName)>
          {{ $t('room') }}
        </template>
        <template #head(name)>
          {{ $t('desk') }}
        </template>
        <template #head(regularUser)>
          {{ $t('employee') }}
          <span
            v-b-tooltip.hover
            class="tip-icon"
            :title="$t('howManageDesks')"
          >
            <font-awesome-icon icon="question-circle" />
          </span>
        </template>
        <template #head(attributes)>
          {{ $t('attributes') }}
        </template>
        <template v-if="isAdmin" #head(svg)>
          {{ $t('svgSnippet') }}
        </template>
        <template #head(reservable)>
          <div class="all-desks-reservable">
            {{ $t('reservable') }}
            <b-form-checkbox
              v-model="parentCheckboxStatus"
              class="m-2 slot-checkbox"
              @change="makeAllDesksReservable($event)"
            >
            </b-form-checkbox>
          </div>
        </template>
        <template #head(actions)>
          <div class="nowrap">
            <span
              v-if="isDashboardAdmin"
              class="btn btn-sm  btn-secondary"
              @click="$bvModal.show('modal-add')"
              >+ {{ $t('add') }}</span
            >
          </div>
        </template>
        <!-- roomName column -->
        <template #cell(roomName)="data">
          <b-form-select
            v-if="isAdmin"
            v-model="data.item.roomId"
            style="width: 160px"
            @change="
              ;(changesDone = true), (data.item.changed = true)
            "
          >
            <option :value="null" disabled>
              {{ $t('selectOption') }}
            </option>
            <template v-for="(room, index) in filteredRooms">
              <option :key="index" :value="room.id">
                {{ room.name }}
              </option>
            </template>
          </b-form-select>

          <span v-else>
            {{ data.item.roomName }}
          </span>
        </template>
        <!-- desk column -->
        <template #cell(name)="data">
          <div>
            <input
              v-if="isAdmin"
              v-model="data.item.name"
              type="text"
              :class="['form-control', emptyFeild(data.item.name)]"
              style="width: 170px"
              :state="!data.item.name ? false : null"
              @change="
                ;(changesDone = true), (data.item.changed = true)
              "
              @input="handleChange($event, 'itemName')"
            />

            <span v-else>
              {{ data.item.name }}
            </span>
          </div>
        </template>
        <template
          v-if="isAdminOrCoordinator"
          #cell(regularUser)="data"
        >
          <div
            :class="[
              'd-flex align-items-center justify-content-center',
              { 'ml-4': !data.item.regularUser }
            ]"
          >
            <UserSelector
              :curr-user="data.item.regularUser"
              :curr-user-object="data.item"
              width="160px"
              @updateUser="
                user => {
                  changesDone = true
                  data.item.changed = true
                  data.item.regularUser = user.email || user.username
                }
              "
            />
            <font-awesome-icon
              v-if="data.item.regularUser"
              class="clear-input-wrapper"
              icon="trash"
              z-index="0"
              @click="
                ;(changesDone = true),
                  (data.item.changed = true),
                  (data.item.regularUser = '')
              "
            />
            <span
              v-if="!data.item.regularUser"
              class="text-green desk-stat p-1"
              >{{ $t('flex') }}
            </span>
          </div>
        </template>
        <template #cell(attributes)="data">
          <div class="d-flex flex-wrap justify-content-center">
            <template v-if="data.item.attributes">
              <base-pill
                v-for="(deskAttribute, index) in data.item.attributes"
                :key="`desk-attribue-${index}`"
                :item="deskAttribute"
              />
            </template>
            <div v-else>
              &mdash;
            </div>
          </div>
        </template>
        <template #cell(svg)="data">
          <input
            v-if="isDashboardAdmin"
            v-model="data.item.svgSnippet"
            type="text"
            :class="[
              'form-control',
              emptyFeild(data.item.svgSnippet)
            ]"
            style="width: 160px"
            @change="
              ;(changesDone = true), (data.item.changed = true)
            "
            @input="handleChange($event, 'svgSnippet')"
          />
        </template>
        <template #cell(reservable)="data">
          <input
            v-if="isAdmin"
            v-model="data.item.reservable"
            type="checkbox"
            @change="
              ;(changesDone = true), (data.item.changed = true)
            "
          />
        </template>
        <template #cell(actions)="data">
          <span
            v-if="isAdmin"
            class="link-remove"
            @click="showRemoveDeskModal(data.item)"
          >
            <font-awesome-icon icon="times" />
            {{ $t('remove') }}
          </span>
          <br />
          <span
            v-if="isAdminOrCoordinator"
            class="link-edit"
            @click="showAttributesModal(data.item)"
          >
            <font-awesome-icon icon="list" />
            {{ $t('attributes') }}
          </span>
        </template>
      </b-table>
      <div class="editdesk-container--foot">
        <button
          type="submit"
          class="btn btn-primary btn-lg"
          :disabled="disbale"
        >
          {{ $t('save') }}
        </button>
      </div>
    </form>
    <b-modal
      id="modal-remove"
      :on-enter-key-press="removeDesk"
      hide-footer
    >
      <template v-slot:modal-title>
        <strong>{{ $t('remove') }}</strong>
      </template>
      <div class="d-block text-center">
        <p>{{ $t('deleteDeskConfirmation') }}</p>
        <b-button
          variant="danger"
          class="mt-1"
          @click="$bvModal.hide('modal-remove')"
        >
          {{ $t('no') }}
        </b-button>
        <b-button class="mt-1 ml-1" primary @click="removeDesk">
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="modal-add"
      :on-enter-key-press="addDesk"
      hide-footer
      @hidden="closeModal"
      @shown="selectDefaultRoom"
    >
      <template v-slot:modal-title>
        <strong>{{ $t('add') }} {{ $t('desk') }}</strong>
      </template>
      <div class="d-block text-center">
        <div class="row">
          <div class="col-6 text-left">
            <div class="form-group">
              <label>{{ $t('desk') }} {{ $t('name') }}:</label>
              <input
                v-model="newDeskName"
                type="text"
                class="form-control"
                :state="errors.name"
              />
              <b-form-invalid-feedback v-if="errors.name">
                {{ errors['name'] }}
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-6 text-left">
            <div class="form-group">
              <label>{{ $t('room') }}:</label>
              <b-form-select v-model="newDeskRoomId">
                <option :value="null" disabled>
                  {{ $t('selectOption') }}
                </option>
                <template v-for="(room, index) in rooms">
                  <option
                    v-if="room.floorId === currentFloor.id"
                    :key="index"
                    :value="room.id"
                  >
                    {{ room.name }}
                  </option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="col-6 text-left">
            <div class="form-group">
              <label>{{ $t('employee') }}:</label>
              <UserSelector
                :curr-user="newDeskEmployee"
                @updateUser="
                  user => {
                    newDeskEmployee = user
                  }
                "
              />
            </div>
          </div>
          <div class="col-6 text-left">
            <div v-if="isDashboardAdmin" class="form-group">
              <label>{{ $t('svgSnippet') }}:</label>
              <input
                v-model="newDeskSvg"
                type="text"
                class="form-control"
              />
              <b-form-invalid-feedback v-if="errors.svgSnippetError">
                {{ errors['svgSnippetError'] }}
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label>{{ $t('reservable') }}:</label>
              <input v-model="newDeskReservable" type="checkbox" />
            </div>
          </div>
        </div>
        <b-button
          variant="danger"
          class="mt-1 ml-1"
          @click="closeModal"
        >
          {{ $t('cancel') }}
        </b-button>
        <b-button class="mt-1 ml-1" primary @click="addDesk">
          {{ $t('save') }}
        </b-button>
      </div>
    </b-modal>
    <attributes-modal
      v-if="selectedValue"
      :attributes="localAttributes"
      :modal-title="`${$t('attributes')} - ${selectedValue.name}`"
      :selected-value="selectedValue"
      @update-selected-attributes="updateSelectedValueAttributes"
      @refresh-data="refreshDesks"
      @updateAlert="showAlert"
    />
  </div>
</template>
<script>
import _pick from 'lodash/pick'
import _uniqBy from 'lodash/uniqBy'
import _find from 'lodash/find'
import { mapGetters, mapState } from 'vuex'

import AttributesMixin from '@/mixins/AttributesMixin'

import OkkuApi from '@/services/OkkuApi'
import { uuidv4 } from '@/services/Helper'

import {
  SET_DESKS,
  TOGGLE_PARENT_CHECKBOX
} from '@/store/modules/common/mutationTypes'

import FloorsDropdown from '@/components/common/FloorsDropdown'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import UserSelector from '@/components/inputs/UserSelector'
import 'vue-search-select/dist/VueSearchSelect.css'

const defaultFields = [
  { key: 'roomName', sortable: true },
  { key: 'name', sortable: true },
  { key: 'regularUser', sortable: true },
  { key: 'attributes', sortable: true },
  { key: 'actions', class: 'text-left' }
]

const adminFields = [
  { key: 'roomName', sortable: true },
  { key: 'name', sortable: true },
  { key: 'regularUser', sortable: true },
  { key: 'attributes', sortable: true },
  'svg',
  'reservable',
  { key: 'actions', class: 'text-left' }
]

const customerAdminFields = [
  { key: 'roomName', sortable: true },
  { key: 'name', sortable: true },
  { key: 'regularUser', sortable: true },
  { key: 'attributes', sortable: true },
  'reservable',
  { key: 'actions', class: 'text-left' }
]

export default {
  name: 'DesksTable',
  components: {
    'b-modal': BootstrapModalHOC,
    FloorsDropdown,
    UserSelector,
    AttributesModal: () =>
      import('@/components/modals/AttributesModal.vue'),
    BasePill: () => import('@/components/common/BasePill.vue')
  },
  mixins: [AttributesMixin],
  data: () => ({
    users: [],
    currentFloor: {},
    sortBy: 'name',
    sortDesc: false,
    filter: null,
    fields: defaultFields,
    filteredDesks: [],
    newDeskName: null,
    newDeskRoomId: null,
    newDeskRoomName: null,
    newDeskEmployee: null,
    newDeskReservable: true,
    disbale: false,
    newDeskSvg: `<rect x='${Math.floor(
      Math.random() * 644
    )}' y='${Math.floor(
      Math.random() * 402
    )}' width='25' height='25'/>`,
    changesDone: false,
    errors: {},
    parentCheckboxStatus: false,
    svgSnippet: false,
    itemName: false
  }),
  computed: {
    ...mapState('common', {
      organisation: '$organisation',
      isDashboardAdmin: '$isDashboardAdmin'
    }),
    ...mapState('common', ['rooms', 'floors', 'desks']),
    ...mapGetters('common', [
      'isAdmin',
      'isAdminOrCoordinator',
      'getParentCheckBoxState'
    ]),
    filteredRooms() {
      return this.rooms.filter(
        ({ floorId }) => floorId === this.currentFloor.id
      )
    }
  },
  watch: {
    floors() {
      const [currentFloor] = this.floors
      this.currentFloor = currentFloor
    },
    currentFloor() {
      this.sortAndFilterDesks()
    },
    filteredDesks() {
      this.lookForMissingFixedUsers()
    },
    sortBy() {
      this.sortAndFilterDesks()
    },
    sortDesc() {
      this.sortAndFilterDesks()
    }
  },
  async created() {
    await this.getAllAttributes()
    await this.refreshDesks()
  },
  mounted() {
    this.parentCheckboxStatus = this.getParentCheckBoxState

    if (this.floors.length > 0) {
      const [currentFloor] = this.floors
      this.currentFloor = currentFloor
    }
    if (this.isDashboardAdmin) {
      this.fields = adminFields
    } else {
      this.fields = this.isAdmin ? customerAdminFields : defaultFields
    }
  },
  beforeDestroy() {
    if (this.changesDone) {
      this.refreshDesks()
    }
  },
  methods: {
    showAlert(payload) {
      this.updateAlert(payload.variant, payload.text)
    },
    updateChanges() {
      this.$emit('updateChanges', this.changesDone)
    },
    updateAlert(variant, text) {
      this.$emit('updateAlert', { variant, text })
    },
    sortAndFilterDesks() {
      this.filteredDesks = this.desks
        .filter(
          desk =>
            desk.floorId === this.currentFloor.id ||
            desk.parentId === this.currentFloor.id
        )
        .sort((a, b) => {
          if (!this.sortBy) {
            return 0
          }
          const multiplier = this.sortDesc ? -1 : 1
          if (
            !a[this.sortBy] ||
            (Array.isArray(a[this.sortBy]) &&
              a[this.sortBy].length === 0)
          ) {
            return 1
          }
          if (
            !b[this.sortBy] ||
            (Array.isArray(b[this.sortBy]) &&
              b[this.sortBy].length === 0)
          ) {
            return -1
          }
          if (this.sortBy === 'name') {
            if (Number(a[this.sortBy]) < Number(b[this.sortBy])) {
              return multiplier * -1
            }
            if (Number(a[this.sortBy]) > Number(b[this.sortBy])) {
              return multiplier
            }
          } else {
            if (a[this.sortBy] < b[this.sortBy]) {
              return multiplier * -1
            }
            if (a[this.sortBy] > b[this.sortBy]) {
              return multiplier
            }
          }
          return 0
        })
    },
    lookForMissingFixedUsers() {
      const unknowUsers = []
      if (!this.users.length) {
        return
      }
      this.filteredDesks
        .filter(desk => desk.regularUser)
        .forEach(desk => {
          if (
            !_find(this.users, {
              username: desk.regularUser
            })
          ) {
            unknowUsers.push(desk.regularUser)
          }
        })
      _uniqBy(unknowUsers).forEach(username => {
        OkkuApi.getBuildingUsers(username).then(matchingUsers => {
          this.users = _uniqBy(this.users.concat(matchingUsers), 'id')
        })
      })
    },
    refreshDesks() {
      OkkuApi.getDesks().then(desks => {
        this.$store.commit(`common/${SET_DESKS}`, desks)
        this.refreshSelectedValue(desks)
        this.sortAndFilterDesks()
      })
    },
    processForm() {
      const desksToUpdate = []

      this.desks
        .filter(desk => desk.changed)
        .forEach(desk => {
          const deskData = {
            ..._pick(desk, [
              'id',
              'name',
              'regularUser',
              'svgSnippet',
              'reservable',
              'context'
            ]),
            parentId: desk.roomId || desk.floorId || desk.parentId
          }

          desksToUpdate.push(deskData)
        })

      // store checkbox state
      this.$store.commit(
        `common/${TOGGLE_PARENT_CHECKBOX}`,
        this.parentCheckboxStatus
      )

      return OkkuApi.updateDesks(desksToUpdate)
        .then(() => {
          this.refreshDesks()
          this.changesDone = false
          this.updateAlert('success', this.$t('changesSaved'))
          this.updateChanges()
        })
        .catch(error => {
          const {
            response: { status }
          } = error
          let message = ''
          if (status === 403) {
            message = this.$t('notAuthorize')
          } else if (status === 422) {
            message = error.response.data?.errors[0]?.msg || 'Failed validation'
          } else {
            message = error.message
          }
          this.updateAlert('danger', message)
        })
    },
    isDeskDataValid() {
      if (!this.newDeskName) {
        this.errors = {
          name: 'Please enter desk name'
        }
        return false
      }
      if (!this.newDeskSvg) {
        this.errors = {
          svgSnippetError: 'Please enter Map snippet (SVG)'
        }
        return false
      }
      return true
    },
    selectDefaultRoom() {
      const rooms = this.rooms.filter(
        ({ floorId }) => floorId === this.currentFloor.id
      )
      if (rooms?.length > 0) {
        this.newDeskRoomId = rooms[0].id
      }
    },
    addDesk() {
      if (!this.isDeskDataValid()) {
        return
      }
      const data = [
        {
          parentId: this.newDeskRoomId || this.currentFloor.id,
          svgSnippet: this.newDeskSvg,
          name: this.newDeskName,
          id: uuidv4(),
          regularUser: this.newDeskEmployee?.email || '',
          reservable: this.newDeskReservable
        }
      ]
      OkkuApi.createDesks(data)
        .then(response => {
          this.refreshDesks()
          this.updateAlert('success', this.$t('changesSaved'))
        })
        .catch(error => {
          let { message } = error
          if (error.response && error.response.data) {
            message = error.response.data
          }
          this.updateAlert('danger', message)
        })
        .finally(() => {
          this.$bvModal.hide('modal-add')
          this.newDeskName = null
          this.errors = {}
          this.newDeskRoomId = null
          this.newDeskRoomName = null
          this.newDeskEmployee = null
          this.newDeskReservable = true
          this.newDeskSvg = `<rect x='${Math.floor(
            Math.random() * 644
          )}' y='${Math.floor(
            Math.random() * 402
          )}' width='25' height='25'/>`
        })
    },
    showRemoveDeskModal(desk) {
      this.selectedValue = desk
      this.$bvModal.show('modal-remove')
    },
    removeDesk() {
      OkkuApi.deleteDesk(this.selectedValue.id)
        .then(response => {
          this.$bvModal.hide('modal-remove')
          this.updateAlert('success', this.$t('changesSaved'))
          this.refreshDesks()
        })
        .catch(error => {
          this.updateAlert('danger', error.message)
        })
    },
    handleFloorSelection(floor) {
      this.currentFloor = floor
    },
    makeAllDesksReservable(checkBoxValue) {
      const { id: floorId } = this.currentFloor

      this.desks.forEach(desk => {
        desk.changesDone = true
        desk.changed = true
        if (
          !desk.floorId ||
          (checkBoxValue && floorId === desk.floorId)
        ) {
          desk.reservable = true
        }

        if (
          !desk.floorId ||
          (!checkBoxValue && floorId === desk.floorId)
        ) {
          desk.reservable = false
        }
      })
      this.parentCheckboxStatus = checkBoxValue
    },
    closeModal() {
      this.errors = {}
      this.$bvModal.hide('modal-add')
    },
    handleChange(event, field) {
      const { value } = event.target
      if (!value) {
        this.empty = 'is-invalid'
        this[field] = true
        this.disbale = this.svgSnippet || this.itemName
        this.errors = {
          updateNameError: 'Name is required',
          svgSnippetError: 'svgSnippet is required'
        }
      } else {
        this[field] = false
        this.disbale = this.svgSnippet || this.itemName
      }
    },
    emptyFeild(data) {
      return !data ? 'is-invalid' : ''
    }
  }
}
</script>
<style lang="scss" scoped>
.clear-input-wrapper {
  font-size: 1.33em;
  margin-left: 5px;
  z-index: 100;
  svg {
    cursor: pointer;
  }
}
@import '../../assets/scss/globals/mixins.scss';
.tip-icon::v-deep {
  .fa-question-circle {
    font-size: 16px;
    margin-right: 5px;
    cursor: pointer;
  }
}
table {
  input {
    display: inline-block;
    vertical-align: middle;
  }
}
.invalid-feedback {
  display: inline;
}
.ui.dropdown::v-deep {
  color: #495057 !important;
  border: 1px solid #ced4da !important;
  display: inline-block !important;
  height: 38px;
  font-size: 16px;
  white-space: nowrap !important;
  padding: 11px 10px !important;
  border-radius: 4px !important;
  .dropdown.icon {
    margin-right: -11px !important;
    padding: 9px 7px !important;
    background-color: #fff;
    z-index: 4 !important;
    position: relative !important;
    top: 0 !important;
    opacity: 1 !important;
    right: 2px !important;
  }
  .menu {
    border-color: #ced4da !important;
  }
  .item.item.item.item {
    padding: 10px !important;
  }
}
.filters-row {
  @include r(780) {
    display: block;
  }
}
.all-desks-reservable {
  display: flex;
  align-items: center;
}
</style>
<style lang="scss">
.bootsrap-table > .table {
  border-radius: 10px;
  background-color: white;
  thead th {
    vertical-align: middle;
  }
}
.table-settings.table-settings {
  td {
    text-align: center !important;
  }
}
</style>
